import { IGrammarNumberForm } from '@goparrot/core-sdk/platform/store-v2'
import { ReadLoyaltyPromotionDto, ReadStoreLoyaltyRewardDto, SpendRuleTargetEnum } from '@goparrot/loyalty-sdk'
import { PromotionApplicationStateEnum } from '@goparrot/promotions-sdk'
import { IRouteParams } from '../'
import { storeUserLoyaltyFacade } from '../api'
import { Dispatch, loyaltyActions, LoyaltyActionType } from '../reducer'
import { AUTH_KEY, PostMessageTypeEnum } from './constants'

export interface IDiscountActionOptions {
    storeId: string
    userId: string
    dispatch: Dispatch<LoyaltyActionType>
}

interface IItemBasedDiscountApplyOptions {
    promotion: ReadLoyaltyPromotionDto
    numberOfApplies?: number
    target: SpendRuleTargetEnum
}

interface ICheckBasedDiscountOptions extends IDiscountActionOptions {
    quantity?: number
}

export const prepareUrlParams = (url: string): IRouteParams => {
    const routeParams = {}
    const params = new URLSearchParams(url)

    params.forEach((value: string, key: string): void => {
        if (AUTH_KEY === key) {
            sessionStorage.setItem(AUTH_KEY, value)
            return
        }

        routeParams[key] = value
    })

    return routeParams as IRouteParams
}
// tslint:disable-next-line:typedef
export const pluralize = (points: number, { plural, singular }: IGrammarNumberForm): string => ([1, -1].includes(points) ? singular : plural)

export const sendPostMessage: (message: PostMessageTypeEnum, payload?: any) => void = (message: PostMessageTypeEnum, payload?: any): void =>
    window.parent.postMessage({ message, payload }, '*')

// TODO: this workaround for change check based reward quantity, should be removed after back end implementation
export const removeAndApplyCheckBasedDiscount = async (ruleUuid: string, options: ICheckBasedDiscountOptions): Promise<void> => {
    const { dispatch, storeId, userId, quantity } = options

    dispatch(loyaltyActions.requestInit())

    try {
        await storeUserLoyaltyFacade.discardLoyaltyReward(storeId, userId, ruleUuid)
        await storeUserLoyaltyFacade.applyLoyaltyReward(storeId, userId, ruleUuid, { quantity })
        const availableDiscountList = await storeUserLoyaltyFacade.retrieveLoyaltyDiscounts(storeId, userId)

        dispatch(loyaltyActions.successGetAvailableDiscounts({ availableDiscountList }))
        sendPostMessage(PostMessageTypeEnum.DISCOUNT_REWARD_SELECTED, { uuid: ruleUuid })
    } catch (err) {
        dispatch(loyaltyActions.requestEnd())
        sendPostMessage(PostMessageTypeEnum.ERROR)
        console.error('Discount applying failed!')
    }
}

export const removeDiscount = async (ruleUuid: string, options: IDiscountActionOptions): Promise<void> => {
    const { dispatch, storeId, userId } = options

    dispatch(loyaltyActions.requestRemoveDiscount())

    try {
        await storeUserLoyaltyFacade.discardLoyaltyReward(storeId, userId, ruleUuid)
        const availableDiscountList = await storeUserLoyaltyFacade.retrieveLoyaltyDiscounts(storeId, userId)

        dispatch(loyaltyActions.successRemoveDiscount({ uuid: ruleUuid, availableDiscountList }))
        sendPostMessage(PostMessageTypeEnum.CANCEL_DISCOUNT_SUCCESS, { uuid: ruleUuid })
    } catch (err) {
        dispatch(loyaltyActions.requestEnd())
        sendPostMessage(PostMessageTypeEnum.ERROR)
        console.error('Discount canceling failed!')
    }
}

export const applyCheckBasedDiscount = async (uuid: string, options: ICheckBasedDiscountOptions): Promise<void> => {
    const { dispatch, storeId, userId, quantity = 1 } = options

    dispatch(loyaltyActions.requestInit())

    try {
        await storeUserLoyaltyFacade.applyLoyaltyReward(storeId, userId, uuid, { quantity })
        const availableDiscountList = await storeUserLoyaltyFacade.retrieveLoyaltyDiscounts(storeId, userId)

        dispatch(loyaltyActions.successGetAvailableDiscounts({ availableDiscountList }))
        sendPostMessage(PostMessageTypeEnum.DISCOUNT_REWARD_SELECTED, { uuid })
    } catch (err) {
        dispatch(loyaltyActions.requestEnd())
        sendPostMessage(PostMessageTypeEnum.ERROR)
        console.error('Discount applying failed!')
    }
}

export const applyItemBasedDiscount = async (uuid: string, options: IItemBasedDiscountApplyOptions): Promise<void> => {
    const { promotion, numberOfApplies, target } = options

    sendPostMessage(PostMessageTypeEnum.ITEM_REWARD_SELECTED, {
        uuid,
        numberOfApplies,
        targets: promotion.discount.targets,
        rewardTarget: target,
        title: promotion.title,
    })
}

export const sortRewards = (list: ReadStoreLoyaltyRewardDto[]): ReadStoreLoyaltyRewardDto[] => {
    if (!list.length) {
        return []
    }

    const initRewardList: Record<keyof typeof PromotionApplicationStateEnum, ReadStoreLoyaltyRewardDto[]> = {
        [PromotionApplicationStateEnum.APPLIED]: [],
        [PromotionApplicationStateEnum.APPLICABLE]: [],
        [PromotionApplicationStateEnum.NON_APPLICABLE]: [],
    }

    const sorted = list
        .sort((a, b) => a.rule.points - b.rule.points)
        .reduce((res, reward) => {
            if (reward.isApplied) {
                res[PromotionApplicationStateEnum.APPLIED].push(reward)
            } else if (reward.isApplicable) {
                res[PromotionApplicationStateEnum.APPLICABLE].push(reward)
            } else {
                res[PromotionApplicationStateEnum.NON_APPLICABLE].push(reward)
            }

            return res
        }, initRewardList)

    return Object.values(sorted).flat()
}

export const upListReward = (list: ReadStoreLoyaltyRewardDto[], specificRewardId: string): ReadStoreLoyaltyRewardDto[] => {
    const idx = list.findIndex(({ rule }) => rule.uuid === specificRewardId)

    if (idx !== -1 && idx) {
        const cloned = [...list]
        const [reward] = cloned.splice(idx, 1)
        cloned.unshift(reward)

        return cloned
    }

    return list
}
