import React, { Suspense, lazy } from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import { Loader } from '../components/uielements/Loader'
import { useStateContext } from '../reducer'

const Loyalty = lazy(async () => import('./Loyalty'))
const Account = lazy(async () => import('./Account'))

export const Router: React.FC = () => {
    const { isLoading } = useStateContext()

    return (
        <BrowserRouter>
            <Suspense fallback={<Loader isLoading={isLoading} />}>
                <Route path="/checkout" component={Loyalty} />
                <Route path="/account" component={Account} />
            </Suspense>
        </BrowserRouter>
    )
}
