import React from 'react'

interface IProps {
    fill: string
}

export const CheckedIcon: React.FC<IProps> = ({ fill }) => (
    <svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="15" cy="15" r="14.5" fill={fill} stroke={fill} />
        <path d="M6.42859 14.9286L13.6466 20.2857L23.5714 9.57141" stroke="white" strokeWidth="3" />
    </svg>
)
