import { createIntl, createIntlCache } from 'react-intl'
import messages from '../i18n/messages'

const cache = createIntlCache()
const DEFAULT_LOCALE = 'en'
const locale: string = (navigator.languages && navigator.languages[0]) || navigator.language || 'en-US'

export const intl = createIntl(
    {
        locale: locale,
        messages: messages[DEFAULT_LOCALE],
    },
    cache,
)

export const getIntlString = (path: string, values?: Record<string, any>): string => intl.formatMessage({ id: path }, values)
