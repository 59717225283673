export const palette: Record<string, string> = {
    defaultTextColor: '#666666',
    borderColor: '#e3e3e3',
    defaultColor: '#044117',
    disabledIconColor: '#eeeeee',
    listItemBackground: '#ffffff',
    mainBackground: '#f9f9f9',
    buttonBgHover: '#066323',
    buttonColorHover: '#ffffff',
    pointsBackground: '#EEEEEE',
    nonActiveColor: '#BCBBBB',
}

export const screenSize: Record<string, string> = {
    XS: '530px',
}
