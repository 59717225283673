import styled, { DefaultTheme, StyledComponent, ThemeProps as _ThemeProps } from 'styled-components'
import { palette } from 'style/constants'

export const Icon: StyledComponent<'div', DefaultTheme> = styled.div`
    grid-area: icon;
    z-index: 2;
    background: #ffffff;
    border-radius: 40px;
    overflow: hidden;
    width: 30px;
    padding: 6px;
    text-align: center;
    line-height: 0;
    background: ${(props: _ThemeProps<DefaultTheme>): string => props.theme.mainColor + '25' || palette.defaultColor};

    img {
        display: initial;
        max-width: 18px;
        max-height: 18px;
        vertical-align: initial;
    }
`
