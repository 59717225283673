import * as React from 'react'
import { IconProps } from './interface'

export const RemoveIcon: React.FC<IconProps> = ({ fill = '#28CD7E' }) => (
    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21 5H19H17H15C15 3.35 13.65 2 12 2C10.35 2 9 3.35 9 5H7H5H3V7H5V18.75C5 20.54 6.66 22 8.7 22H15.3C17.34 22 19 20.54 19 18.75V7H21V5ZM12 4C12.55 4 13 4.45 13 5H11C11 4.45 11.45 4 12 4ZM17 18.75C17 19.43 16.22 20 15.3 20H8.7C7.78 20 7 19.43 7 18.75V7H17V18.75ZM15 9H13V18H15V9ZM9 9H11V18H9V9Z"
            fill={fill}
        />
    </svg>
)
