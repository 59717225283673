import { IGrammarNumberForm } from '@goparrot/core-sdk/platform/store-v2'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { LoyaltyBadge } from '../uielements'
import { HeaderBody } from './header.styled'

export type Props = {
    availablePoints: number
    balanceText?: string
    pointName: IGrammarNumberForm
}

export const Header: React.FC<Props> = ({ balanceText, availablePoints, pointName }) => (
    <HeaderBody data-testid="loyalty-header">
        <span>{balanceText || <FormattedMessage id="user.balance.info" />}</span>
        <LoyaltyBadge isBalance={true} points={availablePoints} pointName={pointName} />
    </HeaderBody>
)
