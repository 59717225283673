import styled, { DefaultTheme, StyledComponent } from 'styled-components'
import { palette } from 'style/constants'

export const ListItem: StyledComponent<'div', DefaultTheme> = styled.div`
    display: flex;
    flex-flow: row nowrap;
    margin: 10px 0;
    background-color: ${palette.listItemBackground};
    border-radius: 5px;
    padding: 10px;
`
