import React from 'react'
import { IGrammarNumberForm } from '@goparrot/core-sdk/platform/store-v2'
import { LoyaltyIcon } from '../LoyaltyIcon'
import * as s from './styled'
import { pluralize } from 'helpers/utils'

type Props = {
    points: number
    isBalance?: boolean
    pointName: IGrammarNumberForm
}

export const LoyaltyBadge: React.FC<Props> = ({ points, isBalance, pointName }) => (
    <s.BadgeLayout isBalance={isBalance} data-testid="loyalty-points-badge">
        <LoyaltyIcon />
        <s.Amount>{points}</s.Amount>
        <s.Label>{`${pluralize(points, pointName)}`}</s.Label>
    </s.BadgeLayout>
)
