export default {
    en: {
        'button.addReward': 'Add Reward',
        'button.selected': 'Selected',
        'button.remove': 'Remove',
        'button.addMore': 'Add more',
        'user.greeting': 'Hi',
        'user.balance.info': 'You have a redemption balance of',
        'user.transaction.type.order': 'Purchased on',
        'user.transaction.type.adjusted': '{points} adjusted',
        'user.transaction.type.signup': '{points} earned on signup',
        'tab.rewards': 'My rewards',
        'tab.history': 'History',
        'tab.howTo': 'How it works',
        'checkout.title': 'Select your rewards',
        'checkout.subtitle': 'Apply any of the available rewards',
        'error.noRewards': 'No rewards available yet',
        'error.422': "Sorry, there isn't available loyalty program",
        'error.400': 'Something went wrong. Please try again in few minutes or skip this step',
        'multi.rewards.quantity.auto.change.error':
            "We're sorry. The {itemName} has limited purchase quantity. We have changed your purchase quantity to the {extreme} allowable.",
        'multi.rewards.quantity.error': 'Sorry, {extreme} {quantity} items are allowed.',
    },
}
