import { ReadStoreLoyaltyRewardDto } from '@goparrot/loyalty-sdk'
import { ActionType, createAction, EmptyActionCreator, PayloadActionCreator } from 'typesafe-actions'
import { ILoyalty, IReward, IWebstorePostMessage } from '../containers/types'

export enum ActionTypeEnum {
    REQUEST_INIT = 'REQUEST_INIT',
    REQUEST_END = 'REQUEST_END',
    GET_LOYALTY_REQUEST = 'GET_LOYALTY_REQUEST',
    GET_LOYALTY_REQUEST_SUCCESS = 'GET_LOYALTY_REQUEST_SUCCESS',
    REMOVE_DISCOUNT_REQUEST = 'REMOVE_DISCOUNT_REQUEST',
    REMOVE_DISCOUNT_SUCCESS = 'REMOVE_DISCOUNT_SUCCESS',
    ITEM_REWARD_SELECTED = 'ITEM_REWARD_SELECTED',
    OPTIONS = 'OPTIONS',
    GET_AVAILABLE_DISCOUNTS_SUCCESS = 'GET_AVAILABLE_DISCOUNTS_SUCCESS',
}

interface ISelectedReward {
    reward: IReward
}

export interface ILoyaltyActionInterface {
    requestInit: EmptyActionCreator<typeof ActionTypeEnum.REQUEST_INIT>
    requestEnd: EmptyActionCreator<typeof ActionTypeEnum.REQUEST_END>

    requestGetLoyalty: EmptyActionCreator<typeof ActionTypeEnum.GET_LOYALTY_REQUEST>
    successGetLoyalty: PayloadActionCreator<ActionTypeEnum.GET_LOYALTY_REQUEST_SUCCESS, ILoyalty>

    requestRemoveDiscount: EmptyActionCreator<typeof ActionTypeEnum.REMOVE_DISCOUNT_REQUEST>
    successRemoveDiscount: PayloadActionCreator<
        typeof ActionTypeEnum.REMOVE_DISCOUNT_SUCCESS,
        { uuid: string; availableDiscountList: ReadStoreLoyaltyRewardDto[] }
    >

    setItemReward: PayloadActionCreator<typeof ActionTypeEnum.ITEM_REWARD_SELECTED, ISelectedReward>
    setOptions: PayloadActionCreator<typeof ActionTypeEnum.OPTIONS, IWebstorePostMessage['payload']>

    successGetAvailableDiscounts: PayloadActionCreator<ActionTypeEnum.GET_AVAILABLE_DISCOUNTS_SUCCESS, { availableDiscountList: ReadStoreLoyaltyRewardDto[] }>
}

export type LoyaltyActionType = ActionType<ILoyaltyActionInterface>

export const loyaltyActions: ILoyaltyActionInterface = {
    requestInit: createAction(ActionTypeEnum.REQUEST_INIT)(),
    requestEnd: createAction(ActionTypeEnum.REQUEST_END)(),

    requestGetLoyalty: createAction(ActionTypeEnum.GET_LOYALTY_REQUEST)(),
    successGetLoyalty: createAction(ActionTypeEnum.GET_LOYALTY_REQUEST_SUCCESS)(),

    requestRemoveDiscount: createAction(ActionTypeEnum.REMOVE_DISCOUNT_REQUEST)(),
    successRemoveDiscount: createAction(ActionTypeEnum.REMOVE_DISCOUNT_SUCCESS)(),

    setItemReward: createAction(ActionTypeEnum.ITEM_REWARD_SELECTED)(),
    setOptions: createAction(ActionTypeEnum.OPTIONS)(),

    successGetAvailableDiscounts: createAction(ActionTypeEnum.GET_AVAILABLE_DISCOUNTS_SUCCESS)(),
}
