import { ReadLoyaltyPromotionDto, SpendRuleTargetEnum } from '@goparrot/loyalty-sdk'
import { PromotionDiscountLevelEnum } from '@goparrot/promotions-sdk'
import * as React from 'react'
import { useIntl } from 'react-intl'
import { PostMessageTypeEnum } from '../../helpers/constants'
import { applyCheckBasedDiscount, applyItemBasedDiscount, removeAndApplyCheckBasedDiscount, removeDiscount, sendPostMessage } from '../../helpers/utils'
import { useDispatchContext, useStateContext } from '../../reducer'
import { AddIcon, LoyaltyBadge, RemoveIcon } from '../uielements'
import { CheckBasedRewardQuantityCounter } from './components/CheckBasedRewardQuantityCounter'
import * as s from './styled'

type Props = {
    uuid: string
    isApplied: boolean
    isApplicable: boolean
    promotion: ReadLoyaltyPromotionDto
    points: number
    numberOfApplies: number
    selectedQuantity: number
    target: SpendRuleTargetEnum
}

export const RewardItem: React.FC<Props> = ({ points, isApplied, isApplicable, promotion, uuid, numberOfApplies, selectedQuantity, target }) => {
    const state = useStateContext()
    const dispatch = useDispatchContext()
    const intl = useIntl()
    const {
        theme,
        isLoading,
        routeParams,
        metadata: { loyaltyPointName },
    } = state
    const { title, image: imageSrc, subTitle } = promotion
    const counterValueRef = React.useRef(selectedQuantity)
    const hasMultipleUsage = promotion.conditions && promotion.conditions.maxUsagesPerCart !== 1
    const params = {
        ...routeParams,
        dispatch,
    }
    const handleNotification = (message: React.ReactNode): void =>
        sendPostMessage(PostMessageTypeEnum.NOTIFICATION, {
            message,
        })

    const handleRemove = (): void => {
        if (isLoading) return

        removeDiscount(uuid, params)
    }
    const handleApply = (): void => {
        if (isLoading) return

        if (PromotionDiscountLevelEnum.CHECK_BASED === promotion.discount.level) {
            applyCheckBasedDiscount(uuid, { ...params, quantity: counterValueRef.current })
            return
        }

        applyItemBasedDiscount(uuid, { promotion, numberOfApplies, target })
    }
    const handleQtyChange = (quantity: number): void => {
        if (isApplied) {
            const options = { ...params, quantity }

            removeAndApplyCheckBasedDiscount(uuid, options)
            return
        }

        counterValueRef.current = quantity
    }

    return (
        <s.Container isSelected={isApplied} disabled={!isApplicable && !isApplied}>
            <s.Image src={imageSrc} />
            <s.Description>
                <h4 data-testid="reward-title">{title}</h4>
                {subTitle && <p data-testid="reward-subtitle">{subTitle}</p>}
                <s.Footer>
                    <LoyaltyBadge points={points} pointName={loyaltyPointName} />
                    {(isApplicable || isApplied) && (
                        <s.ButtonBox>
                            {isApplied && (
                                <s.Button onClick={handleRemove} selected={false} disabled={isLoading} data-testid="remove-reward-btn">
                                    <RemoveIcon fill={theme.mainColor} />
                                    <span>{intl.formatMessage({ id: 'button.remove' })}</span>
                                </s.Button>
                            )}
                            {hasMultipleUsage && promotion.discount.level === PromotionDiscountLevelEnum.CHECK_BASED && (
                                <CheckBasedRewardQuantityCounter
                                    key={counterValueRef.current}
                                    disabled={isLoading}
                                    rewardTitle={title}
                                    max={isApplied ? numberOfApplies + selectedQuantity : numberOfApplies}
                                    selectedQuantity={counterValueRef.current}
                                    onQtyChange={handleQtyChange}
                                    onNotify={handleNotification}
                                />
                            )}
                            {hasMultipleUsage && promotion.discount.level === PromotionDiscountLevelEnum.ITEM_BASED && isApplied && numberOfApplies > 0 && (
                                <s.Button selected={true} onClick={handleApply} data-testid="add-more-btn">
                                    <AddIcon />
                                    <span>{intl.formatMessage({ id: 'button.addMore' })}</span>
                                </s.Button>
                            )}
                            {!isApplied && (
                                <s.Button onClick={handleApply} selected={true} disabled={isLoading} data-testid="add-reward-btn">
                                    <span>{intl.formatMessage({ id: 'button.addReward' })}</span>
                                </s.Button>
                            )}
                        </s.ButtonBox>
                    )}
                </s.Footer>
            </s.Description>
        </s.Container>
    )
}
