import React, { useContext } from 'react'
import { DefaultTheme, ThemeContext } from 'styled-components'
import * as s from './styled'

export const LoyaltyIcon: React.FC = (): React.ReactElement => {
    const theme: DefaultTheme = useContext(ThemeContext)
    return (
        <s.Icon>
            <img src={theme.icon || `${process.env.PUBLIC_URL}/img/reward-icon.svg`} alt="Reward icon" />
        </s.Icon>
    )
}
