import React, { FC, useCallback, useEffect, useRef } from 'react'
import NumberFormat, { NumberFormatProps } from 'react-number-format'
import * as s from './styled'

const isNullishValue = (value?: number | null): boolean => value === undefined || value === null

export type InputNumberProps = Omit<NumberFormatProps, 'onChange'> & {
    min?: number
    max?: number
    // value to be set when input is cleared
    fallbackValue?: number
    value: number
    onChange?: (value: number) => void
    onBlur?: (event: any) => void
    step?: number
    countable?: boolean
    onMinMaxAchieve?(extremum: string, limit: number): void
}

export const InputNumber: FC<InputNumberProps> = ({
    min = -Infinity,
    max = Infinity,
    fallbackValue,
    value,
    onChange = (): void => undefined,
    step = 1,
    countable = true,
    onMinMaxAchieve,
    allowNegative = false,
    disabled,
    ...numberFormatProps
}) => {
    const inputRef = useRef<HTMLInputElement>()
    const isMinReached = value <= min || disabled
    const isMaxReached = value >= max || disabled
    const isNotValid = value < min || value > max
    const handleBlur = useCallback((): void => {
        if (fallbackValue !== undefined && isNullishValue(value)) {
            onChange(fallbackValue)
        } else if (-Infinity !== min && isNullishValue(value)) {
            onChange(min)
        }
    }, [fallbackValue, min, onChange, value])

    const handleChange = useCallback(
        ({ floatValue: value }) => {
            // inputRef?.current?.focus()
            onChange(value)
        },
        [onChange],
    )
    const handleDecreaseButton = useCallback(() => {
        if (value - step >= min) {
            onChange(value - step)
            inputRef?.current?.focus()
        } else if (countable && onMinMaxAchieve) {
            onMinMaxAchieve('min', min)
        }
    }, [value, step, min, onChange, countable, onMinMaxAchieve])

    const handleIncreaseButton = useCallback(() => {
        if (value + step <= max) {
            onChange(value + step)
            inputRef?.current?.focus()
        } else if (countable && onMinMaxAchieve) {
            onMinMaxAchieve('max', max)
        }
    }, [value, step, max, onChange, countable, onMinMaxAchieve])

    useEffect(() => {
        // set empty input when value is undefined
        if (isNullishValue(value)) {
            // @ts-ignore
            inputRef.current.value = ''
        }
    }, [value])

    useEffect(() => {
        if (fallbackValue !== undefined && isNullishValue(value)) {
            onChange(fallbackValue)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <s.Container countable={countable} isMinReached={isMinReached} isMaxReached={isMaxReached} isNotValid={isNotValid}>
            <button aria-label="Decrease value" onClick={handleDecreaseButton}>
                &#8722;
            </button>
            <NumberFormat
                getInputRef={inputRef}
                value={value}
                onValueChange={handleChange}
                allowNegative={allowNegative}
                onBlur={handleBlur}
                {...numberFormatProps}
            />
            <button aria-label="Increase value" onClick={handleIncreaseButton}>
                &#43;
            </button>
        </s.Container>
    )
}
