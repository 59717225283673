// ts-ignore
import styled, { DefaultTheme, StyledComponent, ThemeProps as _ThemeProps } from 'styled-components'
import { palette, screenSize } from '../../style/constants'
import { ListItem } from '../uielements'

interface IRewardContainerProps {
    isSelected: boolean
    disabled: boolean
}

export const Container = styled(ListItem)<IRewardContainerProps>`
    justify-content: flex-start;
    align-items: stretch;
    margin: 0;
    opacity: ${(props: IRewardContainerProps): number => (props.disabled ? 0.5 : 1)};
    pointer-events: ${(props: IRewardContainerProps): string => (props.disabled ? 'none' : 'auto')};
`

export const Image: StyledComponent<'div', DefaultTheme, any> = styled.div<{ src: string }>`
    line-height: 0;
    width: 100px;
    max-height: 100px;
    background-image: ${(props): string => `url(${props.src})`};
    background-size: cover;
    background-position: 50% 50%;
    border: 1px solid ${palette.borderColor};

    img {
        max-width: 100%;
    }

    @media only screen and (max-width: ${screenSize.XS}) {
        width: 84px;
        max-height: 84px;
    }
`

export const Description: StyledComponent<'div', DefaultTheme> = styled.div`
    margin-left: 20px;
    padding: 10px 0 0;
    text-align: left;
    flex: 1;
    color: ${palette.defaultTextColor};

    h4 {
        font-size: 20px;
        font-weight: 500;
        margin: 0 0 5px;
        padding-right: 40px;
        line-height: 1.2;
    }

    p {
        font-size: 14px;
        color: ${palette.nonActiveColor};
    }
`

export const Footer: StyledComponent<'div', DefaultTheme> = styled.div`
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: space-between;
    gap: 16px;

    @media only screen and (min-width: ${screenSize.XS}) {
        flex-flow: row nowrap;
        align-items: center;
    }
`

export const ButtonBox = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    gap: 8px;

    @media only screen and (min-width: ${screenSize.XS}) {
        width: auto;
        margin-top: 0;
    }
`

export const Button = styled.button<{ selected?: boolean }>`
    display: flex;
    align-items: center;
    min-width: 80px;
    min-height: 36px;
    font-size: 14px;
    padding: 0 16px;
    background-color: ${(props): string => (props.selected ? props.theme.mainColor : 'transparent')};
    color: ${(props): string => (props.selected ? '#fff' : props.theme.mainColor)};
    border-width: 1px;
    border-style: solid;
    border-color: ${(props): string => props.theme.mainColor};

    &:hover,
    &:focus {
        border-color: ${(props: _ThemeProps<DefaultTheme>): string => props.theme.mainColor};

        svg path {
            stroke: #fff;
        }
    }

    span {
        margin-left: 4px;
    }

    @media only screen and (min-width: ${screenSize.XS}) {
        font-size: 12px;
    }
`
