import styled, { DefaultTheme, StyledComponent, ThemeProps as _ThemeProps } from 'styled-components'
import { palette } from 'style/constants'

export const LoaderWrapper: StyledComponent<'div', DefaultTheme> = styled.div`
    display: flex;
    min-height: 4px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
`

export const LoadingBar: StyledComponent<'div', DefaultTheme> = styled.div`
    width: 100%;
    position: relative;
    overflow: hidden;
    background-color: #ddd;

    &:before {
        display: block;
        position: absolute;
        content: '';
        left: -200px;
        width: 200px;
        height: 4px;
        background-color: ${(props: _ThemeProps<DefaultTheme>): string => props.theme.mainColor || palette.defaultColor};
        animation: loading 2s linear infinite;
    }

    @keyframes loading {
        from {
            left: -200px;
            width: 30%;
        }
        50% {
            width: 30%;
        }
        70% {
            width: 70%;
        }
        80% {
            left: 50%;
        }
        95% {
            left: 120%;
        }
        to {
            left: 100%;
        }
    }
`
