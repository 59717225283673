import styled, { DefaultTheme, StyledComponent } from 'styled-components'
import { screenSize } from 'style/constants'

export const HeaderBody: StyledComponent<'div', DefaultTheme> = styled.div`
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px 10px 0;
    margin-bottom: 10px;
    border-radius: 5px;
    text-align: center;

    > span {
        text-align: center;

        @media only screen and (min-width: ${screenSize.XS}) {
            text-align: left;
        }
    }

    > div {
        margin-top: 10px;
    }

    @media only screen and (min-width: ${screenSize.XS}) {
        flex-flow: row nowrap;

        > div {
            margin-top: 0;
        }
    }
`
