import { AxiosException } from '@goparrot/common'
import { ReadLoyaltyUserStatusDto, ReadStoreLoyaltyRewardDto } from '@goparrot/loyalty-sdk'
import React, { useEffect, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { loyaltyActions, useDispatchContext, useStateContext } from '../reducer'
import { Router } from './Router'
import { IWebstorePostMessage } from './types'
import { loyaltyFacade, storeUserLoyaltyFacade } from 'api'
import { ErrorMessage, Loader } from 'components/'
import { PostMessageTypeEnum } from 'helpers/constants'
import { getIntlString, intl } from 'helpers/intl'
import { sendPostMessage } from 'helpers/utils'
import { GlobalStyle } from 'style/global.styled'

const DEFAULT_ERROR_STATUS = 400
const DEFAULT_ERROR_MESSAGE = getIntlString(`error.${DEFAULT_ERROR_STATUS}`)

const getErrorMessage = (status: number): string => intl.formatMessage({ id: `error.${status}`, defaultMessage: DEFAULT_ERROR_MESSAGE })

const App: React.FC = (): React.ReactElement => {
    const state = useStateContext()
    const dispatch = useDispatchContext()
    const [errorStatus, setErrorStatus] = useState<number | undefined>()
    const { loyalty, isLoading, theme, routeParams } = state
    const { storeId, merchantId, merchantUserId, orderId, userId } = routeParams

    useEffect(() => {
        const messageHandler = ({ data }: { data: IWebstorePostMessage }): void => {
            const { message, payload }: IWebstorePostMessage = data

            switch (message) {
                case PostMessageTypeEnum.DISCOUNT_REWARD_ADDED:
                case PostMessageTypeEnum.OPTIONS:
                    dispatch(loyaltyActions.setOptions(payload))
                    break
            }
        }
        window.addEventListener('message', messageHandler, false)

        return (): void => {
            window.removeEventListener('message', messageHandler, false)
        }
    }, [dispatch])

    // TODO: show transactions even getLoyaltyDiscounts crashed
    useEffect(() => {
        const getUserStatus = async (): Promise<void> => {
            dispatch(loyaltyActions.requestGetLoyalty())

            try {
                const [status, discountList]: [ReadLoyaltyUserStatusDto, ReadStoreLoyaltyRewardDto[]] = await Promise.all([
                    loyaltyFacade.userStatus(merchantId, storeId, merchantUserId),
                    storeUserLoyaltyFacade.retrieveLoyaltyDiscounts(storeId, userId),
                ])
                dispatch(
                    loyaltyActions.successGetLoyalty({
                        status,
                        discountList,
                    }),
                )
            } catch (error) {
                dispatch(loyaltyActions.requestEnd())
                setErrorStatus((error as AxiosException).response?.data?.statusCode || DEFAULT_ERROR_STATUS)
                console.error(error)
            }
        }

        sendPostMessage(PostMessageTypeEnum.MOUNTED)

        if (!merchantId || !storeId || !merchantUserId || !orderId) {
            console.error('Missed params!')
            setErrorStatus(DEFAULT_ERROR_STATUS)
            return
        }

        getUserStatus()
    }, [merchantId, merchantUserId, orderId, storeId, dispatch, userId])

    useEffect(() => {
        const { availablePoints } = state.loyalty || {}

        if (availablePoints !== undefined) {
            sendPostMessage(PostMessageTypeEnum.USER_BALANCE, { availablePoints })
        }
    }, [state.loyalty])

    return (
        <ThemeProvider theme={theme}>
            <>
                <GlobalStyle />
                <Loader isLoading={isLoading} />
                {loyalty && !errorStatus && <Router />}
                {errorStatus && <ErrorMessage message={getErrorMessage(errorStatus)} />}
            </>
        </ThemeProvider>
    )
}

export default App
