import styled, { css } from 'styled-components'

export const Container = styled.div<{ countable?: boolean; isMinReached: boolean; isMaxReached: boolean; isNotValid: boolean }>`
    /* hide native arrows */
    input[type='number'] {
        -webkit-appearance: textfield;
        -moz-appearance: textfield;
        appearance: textfield;
        padding: 0 20px;
    }
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
    }

    /* reset button styles */
    > button {
        border: none;
        margin: 0;
        padding: 0;
        width: auto;
        min-width: auto;
        overflow: visible;
        background: transparent !important;
        color: inherit !important;
        line-height: 1;
        -webkit-appearance: none;
    }

    button {
        display: none;
    }

    input {
        border: 1px solid lightgrey;
        border-radius: 4px;
        width: 100%;
        text-align: center;
    }

    display: flex;
    position: relative;
    min-width: 65px;
    height: 100%;

    ${({ countable, isMinReached, isMaxReached, isNotValid }): any =>
        countable &&
        css`
            button {
                display: initial;
                position: absolute;
                height: 100%;
                z-index: 1;

                &:first-of-type {
                    left: 0;
                    top: -1px;
                    padding: 0 8px;
                    opacity: ${isMinReached && 0.5};
                    cursor: ${isMinReached && 'default'};
                }
                &:last-of-type {
                    right: 0;
                    top: -1px;
                    padding: 0 8px;
                    opacity: ${isMaxReached && 0.5};
                    cursor: ${isMaxReached && 'default'};
                }
            }

            input {
                opacity: ${isNotValid && 0.5};
            }
        `}
`
