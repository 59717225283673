import styled from 'styled-components'

export const RewardFrame = styled.div`
    position: relative;
    width: 99.5%;
    margin: 10px 0;
`

export const Label = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
`

export const Divider = styled.div`
    border-top: 1px solid rgba(0, 0, 0, 0.15);
`
export const Container = styled.div`
    background-color: #fff;
`
