import { DefaultTheme } from 'styled-components'
import { isActionOf } from 'typesafe-actions'
import { DEFAULT_LOYALTY_POINTS_GRAMMAR_NUMBER_FORM } from '@goparrot/core-sdk/platform/store-v2'
import { ReadLoyaltyUserStatusDto, ReadStoreLoyaltyRewardDto } from '@goparrot/loyalty-sdk'
import { IReward, IWebstoreLoyaltyMetadata } from '../containers/types'
import { createStateProvider } from '../context'
import { palette } from '../style/constants'
import { loyaltyActions, LoyaltyActionType } from './actions'

export type ReducerState = {
    withLoader: boolean
    isLoading: boolean
    loyalty?: ReadLoyaltyUserStatusDto
    availableDiscountList: ReadStoreLoyaltyRewardDto[]
    selectedRewards: IReward[]
    // webstore variables
    theme: DefaultTheme // styles from the webstore. You can check variable list at webstore->constant.service->iframeStyleVariables
    metadata: IWebstoreLoyaltyMetadata
}

export type Dispatch<T> = (action: T) => void

const { defaultColor, defaultTextColor, buttonBgHover, buttonColorHover }: Record<string, string> = palette

const styledTheme: DefaultTheme = {
    mainColor: defaultColor,
    textBase: defaultTextColor,
    buttonColor: '#fff',
    buttonBg: defaultColor,
    buttonColorHover,
    buttonBgHover,
    font: "'Montserrat', sans-serif", // tslint:disable-line
    icon: '',
}

export const initState: ReducerState = {
    isLoading: false,
    withLoader: false,
    metadata: {
        loyaltyPointName: DEFAULT_LOYALTY_POINTS_GRAMMAR_NUMBER_FORM,
    },
    selectedRewards: [],
    availableDiscountList: [],
    theme: styledTheme,
}

export function reducer(state: ReducerState, action: LoyaltyActionType): ReducerState {
    if (isActionOf(loyaltyActions.setOptions, action)) {
        return {
            ...state,
            ...action.payload,
        }
    }

    if (isActionOf(loyaltyActions.setItemReward, action)) {
        return {
            ...state,
            selectedRewards: [...state.selectedRewards, action.payload.reward],
        }
    }

    if (isActionOf(loyaltyActions.successGetAvailableDiscounts, action)) {
        const { availableDiscountList }: { availableDiscountList: ReadStoreLoyaltyRewardDto[] } = action.payload
        return {
            ...state,
            availableDiscountList,
            isLoading: false,
        }
    }

    if (
        isActionOf(loyaltyActions.requestGetLoyalty, action) ||
        isActionOf(loyaltyActions.requestRemoveDiscount, action) ||
        isActionOf(loyaltyActions.requestInit, action)
    ) {
        return {
            ...state,
            isLoading: true,
        }
    }

    if (isActionOf(loyaltyActions.successGetLoyalty, action)) {
        const loyalty: ReadLoyaltyUserStatusDto = {
            ...state.loyalty,
            ...action.payload.status,
        }

        return {
            ...state,
            loyalty,
            availableDiscountList: action.payload.discountList,
            isLoading: false,
        }
    }

    if (isActionOf(loyaltyActions.requestEnd, action)) {
        return { ...state, isLoading: false }
    }

    if (isActionOf(loyaltyActions.successRemoveDiscount, action)) {
        const { availableDiscountList, uuid } = action.payload

        return {
            ...state,
            availableDiscountList,
            selectedRewards: state.selectedRewards.filter((reward: IReward): boolean => reward.uuid !== uuid),
            isLoading: false,
        }
    }

    return state
}

const [ContextProvider, useDispatchContext, useStateContext] = createStateProvider<ReducerState, LoyaltyActionType>(reducer, initState)

export { ContextProvider, useDispatchContext, useStateContext }
