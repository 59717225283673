import styled, { DefaultTheme, StyledComponent } from 'styled-components'
import { palette, screenSize } from 'style/constants'

export const BadgeLayout = styled.div<{ isBalance?: boolean }>`
    display: inline-grid;
    grid-gap: 5px;
    grid-auto-flow: column;
    grid-template-areas: 'icon amount label';
    align-items: center;
    line-height: 16px;
    border-radius: 40px;
    padding-right: 15px;
    font-weight: 500;
    background: ${palette.pointsBackground};
    font-size: ${(props): number => (props.isBalance ? 14 : 11)}px;
    color: ${palette.defaultTextColor};

    @media only screen and (max-width: ${screenSize.XS}) {
        font-size: ${(props): number => (props.isBalance ? 15 : 10)}px;
    }
`

export const Amount: StyledComponent<'div', DefaultTheme, any> = styled.div`
    grid-area: amount;
    padding-left: 5px;
`

export const Label = styled.div`
    grid-area: label;
`
