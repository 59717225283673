import { createGlobalStyle, css, DefaultTheme, FlattenSimpleInterpolation, GlobalStyleComponent, ThemeProps } from 'styled-components'
import { palette } from './constants'

export interface IFont {
    src: string
    name: string
    format: string
    style?: string
    weight?: string
}

function importFonts({ fontList = [] }: DefaultTheme): FlattenSimpleInterpolation {
    const styles: string = fontList.reduce((acc: string, font: IFont): string => {
        return (acc += `
            @font-face {
                font-family: '${font.name}';
                src: url(${font.src}) format('${font.format || 'woff2'}');
                font-style: ${font.style || 'normal'};
                font-weight: ${font.weight || 'normal'};
            }
     `)
    }, '')
    return css`
        ${styles}
    `
}

const { defaultColor, defaultTextColor, buttonBgHover, buttonColorHover }: Record<string, string> = palette

export const styledTheme: DefaultTheme = {
    mainColor: defaultColor,
    textBase: defaultTextColor,
    buttonColor: '#fff',
    buttonBg: defaultColor,
    buttonColorHover,
    buttonBgHover,
    font: "'Montserrat', sans-serif", // tslint:disable-line
    icon: '',
}

export const GlobalStyle: GlobalStyleComponent<ThemeProps<DefaultTheme>, DefaultTheme> = createGlobalStyle`
    ${(props: ThemeProps<DefaultTheme>): FlattenSimpleInterpolation => importFonts(props.theme)}

    * {
      box-sizing: border-box;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    a,
    p,
    li,
    input,
    textarea,
    button,
    select,
    span,
    div,
    html,
    body,
    html a {
        font-family: ${(props: ThemeProps<DefaultTheme>): string => props.theme.font};
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
        color: inherit;
    }

    body {
        font-size: 16px;
        text-align: center;
        font-weight: 400;
        color: ${(props: ThemeProps<DefaultTheme>): string => props.theme.textBase || palette.defaultTextColor};
        background: ${palette.mainBackground};
        margin: 0;
    }

    h1 {
        font-size: 40px;
        font-weight: 600;
        margin: 10px 0;
    }

    p {
        font-size: 14px;
    }

    button {
        border: none;
        padding: 10px 20px;
        border-radius: 4px;
        font-size: 16px;
        color: ${(props: ThemeProps<DefaultTheme>): string => props.theme.buttonColor};
        background: ${(props: ThemeProps<DefaultTheme>): string => props.theme.buttonBg};
        cursor: pointer;
        outline: none;

        &:hover {
          color: ${(props: ThemeProps<DefaultTheme>): string => props.theme.buttonColorHover};
          background: ${(props: ThemeProps<DefaultTheme>): string => props.theme.buttonBgHover};
        }
    }

    #root {
        display: flex;
        flex-flow: column nowrap;
        height: 100%;
        overflow-y: hidden;
    }
`
