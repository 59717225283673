export enum PostMessageTypeEnum {
    MOUNTED = 'MOUNTED',
    CANCEL_DISCOUNT_SUCCESS = 'CANCEL_DISCOUNT_SUCCESS',
    DISCOUNT_REWARD_ADDED = 'DISCOUNT_REWARD_ADDED',
    DISCOUNT_REWARD_SELECTED = 'DISCOUNT_REWARD_SELECTED',
    ITEM_REWARD_SELECTED = 'ITEM_REWARD_SELECTED',
    OPTIONS = 'OPTIONS',
    ERROR = 'ERROR',
    SET_POPUP_ON_LOGIN = 'SET_POPUP_ON_LOGIN',
    USER_BALANCE = 'USER_BALANCE',
    NOTIFICATION = 'NOTIFICATION',
}

export const AUTH_KEY = 'csrfToken'
