import React, { useContext, useReducer, createContext } from 'react'
import { IRouteParams } from '../index'
import { Dispatch } from '../reducer'

interface IRouteProps {
    routeParams: IRouteParams
}

type StateProvider<A, S> = [React.FC<IRouteProps>, () => Dispatch<A>, () => S & IRouteProps]

export const createStateProvider = <S, A>(reducer: React.Reducer<S, A>, initState: S): StateProvider<A, S> => {
    const StateContext = createContext<(S & IRouteProps) | undefined>(undefined)
    const DispatchContext = createContext<Dispatch<A> | undefined>(undefined)

    const ContextProvider: React.FC<IRouteProps> = ({ routeParams, children }) => {
        const [state, dispatch] = useReducer(reducer, initState)

        return (
            <StateContext.Provider value={{ ...state, routeParams }}>
                <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
            </StateContext.Provider>
        )
    }

    const useStateContext = (): S & IRouteProps => {
        const context = useContext(StateContext)

        if (context === undefined) {
            throw new Error('useStateContext must be used within a StateContextProvider')
        }

        return context
    }

    const useDispatchContext = (): Dispatch<A> => {
        const context = useContext(DispatchContext)

        if (context === undefined) {
            throw new Error('useDispatchContext must be used within a DispatchContextProvider')
        }

        return context
    }

    return [ContextProvider, useDispatchContext, useStateContext]
}
