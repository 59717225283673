import * as React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { Counter } from '../../uielements/counter/Counter'

const InputBox = styled.div`
    height: 36px;
    width: 80px;
`

interface Props {
    selectedQuantity: number
    max: number
    onQtyChange(quantity: number): void
    rewardTitle: string
    onNotify(message: React.ReactNode): void
    disabled: boolean
}

export const CheckBasedRewardQuantityCounter: React.FC<Props> = ({ selectedQuantity, max, onQtyChange, rewardTitle, onNotify, disabled }) => {
    const intl = useIntl()
    const handleMinMaxAchieve = (extreme: string, value: number): void => {
        onNotify(intl.formatMessage({ id: 'multi.rewards.quantity.error' }, { quantity: value, extreme }))
    }
    const handleQtyAutoChange = (extreme: string): void => {
        onNotify(intl.formatMessage({ id: 'multi.rewards.quantity.auto.change.error' }, { itemName: rewardTitle, extreme }))
    }

    return (
        <InputBox data-testid="counter-wrapper">
            <Counter
                disabled={disabled}
                value={selectedQuantity}
                onChange={onQtyChange}
                decimalScale={0}
                max={max}
                min={1}
                onMinMaxAchieve={handleMinMaxAchieve}
                onQtyAutoChange={handleQtyAutoChange}
            />
        </InputBox>
    )
}
