import { ReadSpendRuleDto, ReadStoreLoyaltyRewardDto } from '@goparrot/loyalty-sdk'
import * as React from 'react'
import { useStateContext } from '../../reducer'
import { RewardItem } from '../RewardItem'
import { CheckedIcon } from '../uielements'
import { sortRewards, upListReward } from '../../helpers/utils'
import * as s from './styled'

export const RewardRuleList: React.FC = () => {
    const {
        availableDiscountList,
        selectedRewards,
        theme,
        metadata: { urlRewardId },
    } = useStateContext()
    const list = React.useMemo(() => {
        const sorted = sortRewards(availableDiscountList)

        return urlRewardId ? upListReward(sorted, urlRewardId) : sorted
    }, [availableDiscountList, urlRewardId])

    return (
        <s.Container>
            {list.map((preparedRule: ReadStoreLoyaltyRewardDto, idx): null | React.ReactElement => {
                const { rule, isApplicable, isApplied, availableApplicationCount } = preparedRule
                const { promotion, points, uuid, target }: ReadSpendRuleDto = rule
                const { quantity = 1 } = selectedRewards.find((r) => r.uuid === uuid) ?? {}

                return (
                    <React.Fragment key={uuid}>
                        <s.RewardFrame>
                            {isApplied && (
                                <s.Label>
                                    <CheckedIcon fill={theme.mainColor} />
                                </s.Label>
                            )}
                            <RewardItem
                                key={quantity}
                                uuid={uuid}
                                target={target}
                                isApplied={isApplied}
                                isApplicable={isApplicable}
                                promotion={promotion}
                                points={points}
                                selectedQuantity={quantity}
                                numberOfApplies={availableApplicationCount}
                            />
                        </s.RewardFrame>
                        {idx !== list.length - 1 && <s.Divider />}
                    </React.Fragment>
                )
            })}
        </s.Container>
    )
}
