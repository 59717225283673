import 'reflect-metadata'
import 'antd/dist/antd.css'
import React from 'react'
import { render } from 'react-dom'
import { RawIntlProvider } from 'react-intl'
import { intl } from './helpers/intl'
import { prepareUrlParams } from './helpers/utils'
import App from './containers/App'
import { ContextProvider } from './reducer'

declare global {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    interface Window {
        _env_: Record<string, any>
    }
}

export interface IRouteParams {
    merchantId: string
    storeId: string
    merchantUserId: string
    userId: string
    orderId: string
}

render(
    <ContextProvider routeParams={prepareUrlParams(window.location.search)}>
        <RawIntlProvider value={intl}>
            <App />
        </RawIntlProvider>
    </ContextProvider>,
    document.getElementById('root'),
)
