import { IFacadeConfig, IFacadeInterceptors } from '@goparrot/common'
import { LoyaltyApiFacade, StoreLoyaltyRewardFacade } from '@goparrot/loyalty-sdk'
import { AxiosRequestConfig } from 'axios'
import { AUTH_KEY } from '../helpers/constants'

const requestProvideTokenInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig => {
    const token = sessionStorage.getItem(AUTH_KEY)

    if (!config.headers) {
        config.headers = {}
    }

    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`
    }

    return config
}

export const getInterceptors = (): IFacadeInterceptors => ({
    request: [
        {
            onFulfilled: requestProvideTokenInterceptor,
        },
    ],
})

const setAxiosConfig: (serviceUrl?: string) => IFacadeConfig = (serviceUrl?: string): IFacadeConfig => ({
    axiosConfig: {
        baseURL: serviceUrl && `${serviceUrl}/loyalty`,
        timeout: 15000,
    },
    axiosRetryConfig: {
        retries: 4,
        shouldResetTimeout: true,
    },
})

export const loyaltyFacade: LoyaltyApiFacade = new LoyaltyApiFacade(
    setAxiosConfig(window._env_.REACT_APP_GOPARROT_PLATFORM_SERVICE_WEBSTORE_GATEWAY),
    getInterceptors(),
)
export const storeUserLoyaltyFacade: StoreLoyaltyRewardFacade = new StoreLoyaltyRewardFacade(
    setAxiosConfig(window._env_.REACT_APP_GOPARROT_PLATFORM_SERVICE_WEBSTORE_GATEWAY),
    getInterceptors(),
)
